/* Make clicks pass-through */
#nprogress {
	pointer-events: none;
}

#nprogress .bar {
	background: #ffffff;

	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;

	width: 100%;
	height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff;
	opacity: 1.0;

	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the adjust-huener */
#nprogress .adjust-huener {
	display: block;
	position: fixed;
	z-index: 1031;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);
}

#nprogress .adjust-huener-icon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;

	border: solid 2px transparent;
	border-top-color: #ffffff;
	border-left-color: #ffffff;
	border-radius: 50%;

	-webkit-animation: nprogress-adjust-huener 400ms linear infinite;
	animation: nprogress-adjust-huener 400ms linear infinite;
}

.nprogress-custom-parent {
	overflow: hidden;
	position: relative;
}

.nprogress-custom-parent #nprogress .adjust-huener,
.nprogress-custom-parent #nprogress .bar {
	position: absolute;
}

@-webkit-keyframes nprogress-adjust-huener {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes nprogress-adjust-huener {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}