// @import "~antd/dist/antd.scss";
@import "./antd-custom.scss";
@import "./toastify.scss";
@import "./nprogress.scss";

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  height: 100%;
  font-family: 'Rubik', sans-serif;
  background: #100E17;
  color: #f7f7f7;
  font-size: 1.4rem;
}

a:visited,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

a {
  color: #d9d9d9;
  padding: 5px 5px;
  font-weight: bold;
}

a:hover {
  color: #ed1d3c;
}

a:active {
  color: #67686a;
}

.app {
  width: 80%;
  margin: auto;
  overflow: auto;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

header h1 a {
  font-family: 'Rubik', sans-serif;
  font-size: 15rem;
  color: #29292e;
  text-shadow: #7f0d23 0px 0 40px;
  letter-spacing: -10px;
  display: inline-block;
  text-decoration: none;
}

h2 {
  font-size: 3.5rem;
  color: white;
  text-shadow: none;
  letter-spacing: normal;
  font-weight: normal;
  font-family: 'Rubik', cursive;
  position: absolute;
  top: 50%;
  left: 50%;
}

h5 {
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: #ee1e3a;
  margin: 15px 0;
  clear: both;
}

footer {
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 200px;
  text-align: center;
  padding: 4px 0;
}

h1 {
  color: #fff;
}

.logo {
  width: 196px;
  height: 40px;
  background: url(https://80074.s3.eu-west-2.amazonaws.com/static/images/logo.png) no-repeat;
  margin-left: -23px;
}

.ant-layout-header {
  display: flex;
  align-items: center;
}

.ant-layout {
  min-height: 100vh;
}

.error {
  color: red;
}

.top-nav {
  flex: 1;
}

.contactsCover {
  height: 50px;
  max-width: 300px;
  font-size: 15px;
  background-color: rgb(236, 6, 6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  flex-direction: row;
}

textarea {
  height: 110px !important;
}

.form-heading {
  text-align: center;
  margin-bottom: 35px;
}

.logout {
  margin-right: -25px;
}

.ant-form-item.required label:after {
  content: ": *";
}

.ant-upload.ant-upload-select-picture>.ant-upload>img {
  max-width: 400px;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card>.ant-upload>img {
  max-width: 400px;
}

.ant-upload.ant-upload-select-picture-card>.ant-upload>img {
  max-width: 400px;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);

  input {
    width: 130px;
    margin-right: 8px;
  }

  button {
    margin-right: 8px;
  }
}

.add-input {
  &.author {
    width: 400px;
  }
}

// .ant-table-filter-icon {
// 	right: auto;
// 	left: 100px;
// }
th.centred,
.centred {
  text-align: center !important;
}

.ml-30 {
  margin-left: 30px;
}

.float-right {
  float: right;
}

nav {
  padding: 24px 0 0 0;
}

nav a {
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #ffffff;
}

.workName {
  font-size: 2.5rem;
  color: #ffffff;
  font-weight: 600;
  font-family: 'Rubik', sans-serif;
}

.comment .workName {
  margin-top: 2px;
}

.tagline {
  font-size: 1.3rem;
  color: #ff8a00;
  font-weight: 400;
  border-bottom: 1px solid #ff8a00;
  padding-bottom: 8px;
  display: block;
}

.status {
  font-size: 1.1rem;
  color: #EE1E3B;
  font-weight: 600;
  border-bottom: 1px solid #fff;
  padding-bottom: 8px;
}

.time {
  font-size: 0.8rem;
  color: #fff;
  font-weight: 300;
}

.profile strong {
  color: #7a7a8c;
  font-weight: 300;
  // font-size: 1.2rem;
}

.profile {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
}

.mobile {
  font-weight: 700;
  background: linear-gradient(to right, #ff8a00, #da1b60);
  background: -webkit-gradient(linear, left top, right top, from(#ff8a00), to(#da1b60));
  background: linear-gradient(to right, #ff8a00, #da1b60);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  border-bottom: 1px solid #da1b60 !important;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.mobile a {
  padding: 0;
}

.mobileNumber {
  font-size: 2.2rem;
}

.contactDetail {
  margin-top: 8px;
  margin-bottom: 8px;
}

.vip-header {
  position: relative;
  top: -168px;
  left: -70px;
  z-index: 1;
}

.vip-footer {
  display: inline-block;
  position: absolute;
  font-weight: 400;
  text-transform: none;
  text-align: right;
  z-index: 1;
  top: 5px;
  right: 8px;
  display: flex;
  flex-direction: row;
}

.image-gradient-layer {
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.95));
  width: 293px;
  // width: 15vw;
  bottom: 0;
  height: 18vw;
}

.photoComponentHolder {
  flex-direction: row;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.photoComponent {
  display: flex;
  flex-direction: column;
  // width: 15vw;
  // height: 15vw;
  width: 293px;
  height: 293px;
  font-size: 2rem;
  color: #f7f7f7;
  // margin: 30px 3px 0 3px;
  margin: 15px 15px 15px 15px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  // margin: 2% 2% 20px 2%;
  // background: #17141d;
  // max-height: 396px;
  // min-height: 396px;
}

.highpic img {
  width: 100%;
}

.photoComponent a {
  padding: 0;
}

.vip-footer img {
  margin-left: 8px;
  width: 25px;
  height: 25px;
}

.performer {
  margin-bottom: 4px;
}

.ant-form-item-label>label {
  color: #d9d9d9;
  font-weight: bold;
}

.ant-switch {
  background-color: #EE1E3B;
}

.ant-switch-checked {
  background-color: #04b104;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 460px;
  line-height: 160px;
  background: #f8f8f8;
  overflow: hidden;
}

.ant-carousel {
  padding-bottom: 16px;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  color: #29292e;
  font-weight: bold;
}

.text-info {
  color: #67686a;
  font-size: small;
}



#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #bdc3c7;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 28px;
  right: 36px;
  top: 38px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #67686a;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #100E17;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #67686a;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.meta {
  padding: 5px;
  overflow: auto;
  text-align: center;
  // margin: 5px 0;
  position: absolute;
  bottom: 0;
  left: 8px;
}

.meta button {
  font-weight: 400;
  letter-spacing: 1px;
  font-size:  7em;
  font-family: 'Rubik', sans-serif;
  color: #c20018;
  border: none;
  background: transparent;
  float: left;
  width: 100%;
  text-align: center;
  outline: none;
}

.meta p {
  // padding-top: 3px;
  font-weight: 700;
  text-transform: uppercase;
  // letter-spacing: 2px;
  font-size: 1.3rem;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  float: left;
  width: 100%;
  text-align: left;
}

.meta a {
  text-decoration: none;
  float: left;
  width: 100%;
  text-align: center;
  // margin-top: 3px;
  font-size: 0.75em;
}

.meta a:hover {
  color: #EE1E3B;
}

.meta svg {
  font-size: 1.3rem;
  margin-right: 4px;
}

.disclaimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}



.disclaimer,
.disclaimer div {
  padding: 20px;
  text-align: center;
}


@media only screen and (min-device-width: 767px) {

  .disclaimer,
  .disclaimer div {
    padding: 20px 20vw;
  }

  .mobileNumber {
    font-size: 4rem;
  }

}

.disclaimer p {
  font-weight: 300;
  font-size: 12px;
}

.disclaimer h4 {
  color: white
}

.mega-header-header {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ant-form-item-label>label {
  color: #d9d9d9 !important;
  font-weight: bold;
}

.ant-switch {
  background-color: #EE1E3B !important;
}

.ant-switch-checked {
  background-color: #04b104 !important;
}


.h1,
.header-breadcrumbs .breadcrumb_last,
h1 {
  font-family: 'Rubik', sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #ee1e3a;
  margin-bottom: 50px;
  margin-top: 30px;
}

.mega-header {
  background: #100e17;
  color: #fff;
  position: relative;
  overflow: hidden;
  padding: 4rem 2rem 2rem;
}

.tag {
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
  white-space: nowrap;
  border: 3px solid #480817;
  border-radius: 2rem;
  margin-bottom: 12px;
  padding: .5rem 1rem;
  line-height: 1.8;
}

.tag:hover,
.tag:focus,
.tag:active {
  background: #480817;
}

a.tagLink {
  padding: 0;
}

@media only screen and (min-device-width: 767px) {
  .tag {
    font-size: 1rem;
    margin: 1rem;
    min-width: 12vw;
    display: inline-block;
    width: fit-content;
  }

}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (max-device-width: 767px) {

  .tag {
    margin-bottom: 0px;
  }

  .photoComponent {
    min-width: 100%;
    min-height: 100%;
    // min-width: 40vw;
    // min-height: 40vw;
    margin-bottom: 8px;
  }

  .image-gradient-layer {
    width: 100%;
    height: 38vw;
  }

  .viewBy {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .viewBy .tag {
    flex: 1;
    width: 27vw;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.0rem;
    white-space: nowrap;
    border: 3px solid #480817;
    border-radius: 2rem;
    margin-right: 5px;
    margin-bottom: 8px;
    padding: 0.4rem 0 0.3rem;
    line-height: 1.8;
  }

  .viewBy .tag:hover,
  .viewBy .tag:focus,
  .viewBy .tag:active {
    background: #480817;
  }

  .App {
    margin: 2rem;
  }

  .h1,
  .header-breadcrumbs .breadcrumb_last,
  h1 {
    font-size: 18px;
    margin-bottom: 15px;
    margin-bottom: 20px;
  }

  .mobile {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .mobile a {
    padding: 3px 0;
  }

  .disclaimer p {
    font-size: 10px;
  }

  .meta p {
    // padding-top: 3px;
    font-weight: 700;
    text-transform: uppercase;
    // letter-spacing: 2px;
    font-size: 1.2rem;
    font-family: 'Rubik', sans-serif;
    color: #fff;
    float: left;
    width: 100%;
    text-align: left;
    bottom: 8px;
  }

  .vip-footer {
    top: 5px;
    right: 5px;
  }

  body {
    font-size: 1.2rem;
  }

  form {
    margin: 30px;
  }

  .display_image img {
    width: 100%;
    margin-bottom: 24px;
  }
}


@media only screen and (min-device-width: 751px) and (max-device-width: 1540px) and (-webkit-min-device-pixel-ratio: 2) {

  .h1,
  .header-breadcrumbs .breadcrumb_last,
  h1 {
    font-size: 42px;
  }

}


.psych-btn {
  background-image: -webkit-gradient(linear, right bottom, left top, from(#e50606), to(#da1b60));
  background-image: linear-gradient(to top left, #e50606, #da1b60);
  color: #fff !important;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  font-size: 1.5rem;
  border: 0;
  border-radius: 1rem;
  white-space: nowrap;
  padding: 1rem 1.5rem;
  line-height: 1.4;
  position: relative;
  text-align: center;
  -webkit-transition: 70ms;
  transition: 70ms;
  position: relative;
  margin-left: 2rem;
}

.alert.alert-danger {
  padding: 0 2rem 2rem 2rem;
  color: #e50606;
}

.alert-link {
  width: 100%;
  text-align: left;
  margin: 2px 0 0px;
}

a:hover .alert-link {
  text-decoration: underline !important;
}

.ant-btn-block {
  width: 50vw;
}

.makeAvailable {
  border-radius: 2em;
  padding: .75em 1.5em;
  background-color: #69e781;
  border: none;
  border-radius: 3px;
  color: #0b2239;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  padding: .75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  -webkit-transition: all .1s cubic-bezier(.165, .84, .44, 1);
  -moz-transition: all .1s cubic-bezier(.165, .84, .44, 1);
  transition: all .1s cubic-bezier(.165, .84, .44, 1);
}

.redirect {
  border-radius: 2em;
  padding: .75em 1.5em;
  background-color: #3677E5;
  border: none;
  border-radius: 3px;
  color: #0b2239;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  margin-top: 20px;
  padding: .75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  -webkit-transition: all .1s cubic-bezier(.165, .84, .44, 1);
  -moz-transition: all .1s cubic-bezier(.165, .84, .44, 1);
  transition: all .1s cubic-bezier(.165, .84, .44, 1);
}

.deactivate {
  border-radius: 2em;
  padding: .75em 1.5em;
  background-color: #8D949E;
  border: none;
  border-radius: 3px;
  color: #0b2239;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  padding: .75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  -webkit-transition: all .1s cubic-bezier(.165, .84, .44, 1);
  -moz-transition: all .1s cubic-bezier(.165, .84, .44, 1);
  transition: all .1s cubic-bezier(.165, .84, .44, 1);
}

.discover-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.discover-footer-cell {
  margin-top: 35px;
  margin-bottom: 40px;
  width: 60px;
}

@media (max-device-width: 767px) {

  .discover-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .discover-footer-cell {
    margin-bottom: 25px;
    margin-top: 0;
    width: 100%;
  }

}

.maxedProfiles {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30vh;
  font-size: 1.5em;
  font-weight: 600;
  width: 100%;
  padding: 30px;
  text-align: center;
}

.activeLink {
  cursor: pointer;
}

.ant-input-group-addon {
  z-index: 55 !important;
}

a:hover, a:focus {
  color: #ed1d3c;
}