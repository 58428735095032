// form{
//     padding: 5px;
//     border-top: 1px solid #dce2e4;
//     overflow: auto;
//     position: absolute;
//     width: 100%;
//     left: 0;
//     bottom: 0;
// }
form input {
    padding: 9px 3px;
    width: 90%;
    margin: 5px auto;
    display: block;
    border: none;
    outline: none;
    color: white
}

form a {
    text-decoration: none;
    margin-top: 5px;
    float: left;
    width: 50%;
    text-align: center;
    padding: 9px 0;
    margin-top: 10px;
    color: white;
}

.profile-details {
    padding: 0 30px 10px 30px;
}

.profile-details p {
    margin: 10px 0;
}

.profile-details strong {
    display: block;
}

.display_image {
    width: 50%;
    // flex-direction: column;
    // justify-content: center;
    // display: flex;
}

.display_image img {
    width: 70%;
    margin-bottom: 24px;
}

.display_image .video {
    width: 70%;
    margin-bottom: 24px;
}

.profile-details {
    position: relative;
    width: 50%;
}

.container {
    display: flex;
    width: 100%;
    // color: #Aa;
    box-shadow: -12px 16px 75px -27px rgba(0, 0, 0, 1);
}

.meta #location {
    // padding-top: 3px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.5em;
    font-family: 'Rubik', sans-serif;
    color: white;
    float: left;
    width: 100%;
    text-align: left;
}

.display_reviews {
    width: 50%;
}

.container_reviews {
    flex-direction: row;
}

.review_block {
    margin: 10px 0px;
    padding-bottom: 16px;
    padding-top: 8px;
    border-bottom: 4px solid slategrey;
}

.review_block .reviewtext {
    font-size: 1.3em;
    margin-bottom: 20px;
}


/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) {

    .display_reviews {
        width: 100%;
    }

    .container_reviews {
        flex-direction: column;
    }


    .profile-details strong {
        display: block;
        font-size: 1.3rem;
    }

    .meta #location {
        // padding-top: 3px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.5em;
        font-family: 'Rubik', sans-serif;
        color: #fff;
        float: left;
        width: 100%;
    }

    .display_image img {
        width: 100%;
        margin-bottom: 24px;
    }

    #close-gallery {
        width: 100%;
        padding: 20px 20px 0 0;
    }

    #close-gallery img {
        margin-bottom: 0;
    }

    // .display_image .video {
    //     width: 80%;
    //     margin-bottom: 24px;
    // }


}

.location-link {
    text-decoration: underline;
    padding-left: 0;
    padding-right: 0;
}

.verified-blue {
    color: rgb(29, 155, 240);
}

.delete-container {
    position: absolute;
    left: 30%;
    bottom: -10px;
    cursor: pointer;
}

.delete-container img {
    width: 100px
}

.single-image {
    width: 100%;
    position: relative;
    min-height: 200px;
}