:global(body) {
    //     margin: 0;
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, Avenir Next, Avenir, Helvetica, sans-serif;
}

nav {
    text-align: center;
    padding-right: 40px;
}

.nav-ul {
    display: flex;
}

nav>ul {
    padding: 4px 16px;
    display: flex;
    justify-content: space-between;
}

li {
    display: flex;
    padding: 6px 8px;
}